import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GroupDescriptor, process } from '@progress/kendo-data-query';
import Branch from '../../core/data-models/branch';
import LaborSummary from '../../core/data-models/labor/labor-summary';
import LaborQuery from '../../core/data-models/labor/labor-query';
import LaborStatus from '../../core/data-models/labor/labor-status';
import { LaborHttpService } from '../../core/services/http/labor-http/labor-http.service';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import { PromptDialogComponent } from '../../shared/components/dialogs/prompt-dialog-component/prompt-dialog.component';
import LaborReject from '../../core/data-models/labor/labor-reject';
import PromptDialogOption from '../../core/data-models/prompt-dialog-option';
import { LaborEditComponent } from './labor-edit/labor-edit.component';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import LaborSummaryWithAttributes from '../../core/data-models/labor/labor-summary-with-attributes';
import { NewRelicUtilityService } from '../../core/services/utility/new-relic-utility.service';
import ActionProgressReporterOption from '../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import ProjectIdUtility from '../../core/services/utility/project-id-utility/project-id-utility.service';
import { DownloadHttpService } from '../../core/services/http/download-http/download-http.service';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { LaborStatusService } from '../../core/services/labor-status/labor-status.service';
import DuplicateLaborCheckDto from '../../core/data-models/labor/duplicate-labor-dto';
import ConfirmDialogOption from '../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'app-labor',
    styleUrls: ['./labor.component.scss'],
    templateUrl: './labor.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LaborComponent implements OnInit {

    @Input() public jobTypes: string[];
    @Input() public branches: Branch[];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    public gridViewData: LaborSummaryWithAttributes[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public query: LaborQuery;
    public laborStatus: LaborStatus[];
    public groups: GroupDescriptor[] = [{ field: 'employeeName', aggregates: [{ field: 'hours', aggregate: 'sum' }] },
        { field: 'jobDate', aggregates: [{ field: 'hours', aggregate: 'sum' }] }];

    private _detailsFlag = false;

    get detailsFlag(): boolean {
        return this._detailsFlag;
    }

    get showAvantiExport(): boolean {
        if (!this._featureFlagService.featureFlags[FeatureFlags.ShowExportInAvantiFormatButton]) {
            return false;
        }

        const selectedBranch = this.branches.find(_ => _.name === this.query?.branch);

        return selectedBranch? ProjectIdUtility.isUsProject(selectedBranch.code) : false;
    }

    //eslint-disable-next-line
    constructor(
        private _laborHttpService: LaborHttpService,
        private _userHttpService: UserHttpService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _featureFlagService: FeatureFlagService,
        private _newrelicService: NewRelicUtilityService,
        private _downloadService: DownloadHttpService,
        private _laborStatusService: LaborStatusService
    ) {
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.query = new LaborQuery();
        this.laborStatus = await this._laborHttpService.getLaborStatus();
        const user = await this._userHttpService.getUserInfo();
        this.query.branch = user.groups.some(_ => _.toLowerCase() === 'admin') ? '': user.branch;
        this.query.status = 'Submitted';
    }

    public async loadLaborRecords(): Promise<void> {
        this.loading = true;
        this.dataBinding.skip = 0;
        this.gridViewData = await this._laborHttpService.getLaborSummaryWithAttributes(this.query);
        this.loading = false;
    }

    public canEdit(labor: LaborSummary) : boolean {
        return labor.type === 0 && labor.status ==='Submitted By Tech';
    }

    public async retryLabor(labor: LaborSummary) : Promise<void> {
        try {
            this._newrelicService.addPageAction('Action', 'Retry', 'LaborModule', labor);

            // check duplicates
            const duplicateCheck: DuplicateLaborCheckDto = { employeeId: labor.employeeId, approvedHours: labor.hours.toString(), projectId: labor.recordId, jobDate: labor.jobDate };

            const isDuplicate = await this._laborHttpService.checkApprovedDuplicates(duplicateCheck);

            if (isDuplicate) {
                const shouldApproveDuplicate = await this.confirmDuplicateDialog(labor);
                if (!shouldApproveDuplicate) {
                    return;
                }

                this.loading = true;
                await this._laborHttpService.retryUploadLabor(labor);
            }
            else {
                await this._laborHttpService.retryUploadLabor(labor);
            }

            await this.loadLaborRecords();
        }
        finally {
            this.loading = false;
        }
    }

    public async approveLabor(labor: LaborSummary): Promise<void> {
        if (this.loading) {
            return;
        }

        this.loading = true;

        try {
            const duplicateCheck: DuplicateLaborCheckDto = {
                employeeId: labor.employeeId,
                approvedHours: labor.hours.toString(),
                projectId: labor.recordId,
                jobDate: labor.jobDate
            };

            const isDuplicate = await this._laborHttpService.checkApprovedDuplicates(duplicateCheck);

            if (isDuplicate) {
                const shouldApproveDuplicate = await this.confirmDuplicateDialog(labor);
                if (!shouldApproveDuplicate) {
                    return;
                }
            }

            const response = await this._laborHttpService.approveLabor(labor);

            if (response.code !== '200') {
                const data = new ActionProgressReporterOption(
                    Promise.resolve(false),
                    null,
                    null,
                    response.description
                );
                this._dialog.open(ActionProgressDialogComponent, { data, width: '520px', height: '120px' });
            }
            await this.loadLaborRecords();
        }
        catch (error) {
            console.error('Error approving labor:', error);
        }
        finally {
            this.loading = false;
        }
    }

    private confirmDuplicateDialog(labor: LaborSummary): Promise<boolean> {
        const title = this._translate.instant('labour.duplicateApprovedPrompt');
        const htmlmessage = `<p>${this._translate.instant('labour.duplicateApprovedMessage')}</p><p>${this._translate.instant('labour.duplicateApprovedBody', {
            projectId: labor.recordId,
            hours: labor.hours,
            techName: labor.employeeName,
            projectName: labor.recordName,
            jobDate: labor.jobDate
        })}</p>`;
        const data = new ConfirmDialogOption(title, null, false, this._translate.instant('labour.duplicateLaborApproveCTA'), this._translate.instant('labour.duplicateLaborApproveCancel'), htmlmessage);
        const dialog = this._dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        return dialog.afterClosed().toPromise();
    }

    public async rejectLabor(labor: LaborSummary) : Promise<void> {
        this.loading = true;
        try {
            this._newrelicService.addPageAction('Action', 'Reject', 'LaborModule', labor);
            const rejectLabour = this._translate.instant('labour.rejectLabor');
            const enterReason = this._translate.instant('labour.pleaseEnterReason');
            const reject = this._translate.instant('labour.reject');

            const dialog = this._dialog.open(PromptDialogComponent, {
                data: new PromptDialogOption(rejectLabour, enterReason, true, reject),
                width: '550px',
                height: '450px'
            });

            const reason = await dialog.afterClosed().toPromise();
            if (reason) {
                const laborReject: LaborReject = { ...labor, reason };
                await this._laborHttpService.rejectLabor(laborReject);
                await this.loadLaborRecords();
            }
        }
        finally {
            this.loading = false;
        }
    }

    public async editLabor(labor: LaborSummary) : Promise<void> {
        this.loading = true;
        try {
            this._newrelicService.addPageAction('Action', 'Edit', 'LaborModule', labor);
            const dialog = this._dialog.open(LaborEditComponent, {
                data: labor
            });
            await dialog.afterClosed().toPromise();
        }
        finally {
            this.loading = false;
        }
    }

    public allData(): ExcelExportData {
        this._newrelicService.addPageAction('Action', 'ExportToExcel', 'LaborModule', { Details: this.detailsFlag });

        const data = this.gridViewData.map(_ => ({
            ..._,
            status: this.translateStatus(_.status),
            unionOverride: _.overrides.union ? `${_.overrides.union.id} - ${_.overrides.union.name}` : '',
            wageCodeOverride: _.overrides.wageCode ? `${_.overrides.wageCode.code} - ${_.overrides.wageCode.description}` : ''
        }));

        const result = this._detailsFlag?
            { data: process(data, { }).data } :
            { data: process(data, { group: this.groups }).data, group: this.groups };

        return result;
    }

    public exportDetailsToExcel(grid: GridComponent): void {
        this._detailsFlag = true;
        setTimeout(() => {
            grid.saveAsExcel();
            this._detailsFlag = false;
        });
    }

    public generateAvantiExport(): void {
        const ongoing = this._translate.instant('labour.avantiExportOnGoing');
        const error = this._translate.instant('labour.avantiExportFailed');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const action = this.generateAvantiExportInternal();

        const dialogData = new ActionProgressReporterOption(action, ongoing, '', error, ok, gotIt, false);

        this._dialog.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });
    }

    public translateStatus(labourStatus: string): string {
        return this._laborStatusService.getTranslation(labourStatus);
    }

    private async generateAvantiExportInternal(): Promise<boolean> {
        const response = await this._laborHttpService.generateAvantiExport(this.query);
        return response? this._downloadService.downloadBlob(response.blob, response.fileName) : false;
    }

    public rowColor(context: RowClassArgs): {passive: boolean} {
        const response = context.dataItem.hours;
        return { passive: response<=0 };
    }
}